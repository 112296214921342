// Migrated
<template lang="pug">
LayoutWrapper.d-block
  template(#header)
    Header
  template(#banner)
    Banner
  template(#hero)
    Hero
  template(#sidebar)
    Sidebar
  template(#content)
      MemberNavTabs(
        animated
        @on-tab-change="updateActiveTab"
      )
      slot
  template(#footer)
    .w-100.d-inline-block.print-fullpage.print-break-before.mt-3.mt-md-4(ref="extraFooterRef")
      //- TripFooter
    NuxtLazyHydrate(:when-visible="{ rootMargin: '50px' }")
      LazyFooter
</template>

<script setup>
const route = useRoute()
const { urls: localeURLs } = useLocale()
const { t } = useI18n()
const userStore = useUserStore()

const extraFooterRef = ref()
const slug = route.params.slug
const activeTab = findKeyBySlug(slug, localeURLs.authSlug)
userStore.SET_MEMBER_PAGE_TAB(activeTab)

useHead({
  titleTemplate: `%s - ${t('siteTitleBrand')}`,
  link: () => [
    // { rel: 'icon', type: 'image/x-icon', href: t('favicon') },
    {
      rel: 'canonical',
      href: () => `${localeURLs.BASE_URL}$`,
    },
  ],
  meta: () => [
    { hid: 'author', name: 'author', content: t('siteTitleBrand') },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: () => `${t('siteTitleBrand')}`,
    },
    {
      hid: 'og:url',
      property: 'og:url',
      content: () => `${localeURLs.BASE_URL}`,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () => `%s - ${t('siteTitleBrand')}`,
    },
  ],

})

const updateActiveTab = (newActiveTab) => {
  userStore.SET_MEMBER_PAGE_TAB(newActiveTab)
}
</script>
